import React from 'react';
import { getImageForEPC } from '../ultility';
import { getPropertyData } from './use-property';

interface IEPCProps {
    showFlanders?: boolean;
    showBrussels?: boolean;
    showWallonia?: boolean;
}

const EPCComponent = (props: IEPCProps) => {
    const property = getPropertyData();
    const { showFlanders, showBrussels, showWallonia } = props;

    const showF = showFlanders === false ? false : true;
    const showB = showBrussels === false ? false : true;
    const showW = showWallonia === false ? false : true;

    const { type, image } = getImageForEPC(property);

    return (
        <>
            {!!property.EnergyPerformance && <div>
                {type === 'Flanders' && showF && <div>
                    <div className='EPC_Label'></div><img src={image} style={{ border: 0, width: '70px', verticalAlign: 'middle' }} alt='epc_label' />
                </div>}
                {type === 'Brussels' && showB && <div>
                    <div className='EPC_Label'></div><img src={image} style={{ border: 0, width: '180px', verticalAlign: 'middle' }} alt='epc_label' />
                </div>}
                {type === 'Wallonia' && showW && <div>
                    <div className='EPC_Label'></div><img src={image} style={{ border: 0, width: '70px', verticalAlign: 'middle' }} alt='epc_label' />
                </div>}
            </div>}
        </>
    );
};

export default EPCComponent;
