import React from 'react';
import HtmlBind from '../../../components/html-binding';
import { SectionTypeEnum } from '../../../model/airtable.model';
import { useDispatch } from 'react-redux';
import VisibilitySensor from 'react-visibility-sensor';
import Lazyload from 'react-lazyload';
import { changeMenuActive } from '../../../state/menu';

import './styles.scss';
import { useTranslation } from 'react-i18next';

interface ISectionProps {
    index: number;
    type: SectionTypeEnum;
    title?: string;
    description?: string;
    className?: string;
    lazyload?: boolean;
    name?: string;
    children: any;
}

const Child = (props: ISectionProps) => {
    const isLazyLoad = props.lazyload === true ? true : false;
    return <>
        {isLazyLoad && <Lazyload height={400} offset={100}>
            <div className={props.name}>{props.children}</div>
        </Lazyload>}
        {!isLazyLoad && <div className={props.name}>{props.children}</div>}
    </>;
}

export default (props: ISectionProps) => {
    const title = props.title || '';
    const description = props.description || '';
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const setMenuActive = (isVisible: boolean, menu: string) => {
        if (isVisible) {
            dispatch(changeMenuActive(menu));
        }
    };

    return (
        <section
            id={props.name}
            className={`section section-${props.index} section-${props.type}-${props.index} ${
                !!props.className ? props.className : ''
                }`}>
            {(!!title || !!description) && (
                <div className="header">
                    <VisibilitySensor onChange={(isVisible: boolean) => setMenuActive(isVisible, props.name || '')}>
                        <div>
                            <h2 className="title">{t(title)}</h2>
                            <HtmlBind value={description} className="description" />
                        </div>
                    </VisibilitySensor>
                </div>
            )}
            <Child {...props} />
        </section>
    );
};
