import React from 'react';
import Image from '../../../components/gatsby-image';
import { getPropertyData } from '../../../components/use-property';
import RenderConfigFE from '../../../components/render-config-fe';
import PropertyGeneral from './general';
import PropertyEPC from './epc';
import PropertyFinance from './finance';
import PropertyPlanning from './planning';
import './styles.scss';
import { getMainPropertyPicture } from '../../../ultility';
import { IAirtableAttachment } from 'src/model/airtable.model';

export interface IPropertyInfoProps {
    image?: IAirtableAttachment;
}

const PropertyInfo = (props: IPropertyInfoProps) => {
    const property = getPropertyData();
    console.log('PropertyInfo', props);
    const propertyPicture = getMainPropertyPicture(property);
    return (
        <div className="info__content row">
            <div className="info__content-left col-sm-12 col-md-6 col-lg-6">
                {!!propertyPicture && <Image name={propertyPicture.Name} />}
                {!propertyPicture && !!props.image && <Image name={props.image.id} />}
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6">
                <div className="info__content-right">
                    <div className="image-info hidden-sm">
                        <RenderConfigFE name="Logo" type="image" />
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            <PropertyFinance />
                            <PropertyGeneral />
                            <PropertyEPC />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            <PropertyPlanning />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PropertyInfo;
