import React from 'react';
import ReactMarkdown from 'react-markdown';

export interface IHtmlBindProps {
    value: string;
    className?: string;
}

const HtmlBind = (props: IHtmlBindProps) => {
    if (typeof window !== 'undefined') {
        let doc = new DOMParser().parseFromString(props.value, 'text/html');
        let isHtml = Array.from(doc.body.childNodes).some((node) => node.nodeType === 1);

        if (isHtml) {
            return <div className={props.className} dangerouslySetInnerHTML={{ __html: props.value }}></div>;
        } else {
            return <ReactMarkdown className={props.className}>{props.value}</ReactMarkdown>;
        }
    }
    return null;
};

export default HtmlBind;
