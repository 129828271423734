import React from 'react';
import Image from '../../../components/gatsby-image';
import PlayIcon from '../../../components/play-icon';
import { getPropertyData } from '../../../components/use-property';
import { getMainPropertyPicture } from '../../../ultility';
import './styles.scss';

export interface IVirtualTourProps {
    title?: string;
}

function goToLink(url: string) {
    if (typeof window !== 'undefined') {
        window.open(url, '_blank');
    }
}

const VirtualTourComponent = (props: IVirtualTourProps) => {
    const property = getPropertyData();
    const propertyPicture = getMainPropertyPicture(property);

    return (
        <>
            <div className="virtual__content">
                <div className="virtual__content--banner">
                    <button
                        onClick={() => goToLink(property.VirtualTour || property.VirtualTour2 || '')}
                        className="btn btn-play"
                        aria-label="view-virtual-tour">
                        <PlayIcon />
                    </button>
                    <span className="title">{props.title}</span>
                </div>
                {propertyPicture && <Image name={propertyPicture.Name} />}
            </div>
        </>
    );
};

export default VirtualTourComponent;
