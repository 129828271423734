import React, { useEffect, useState } from 'react';
import { getPropertyData } from '../../../src/components/use-property';
import loadable from '@loadable/component';
import { getConfigFrontEnd } from '../../../src/components/use-config-fe';
import { CustomerInfo } from '../../../build-info';
const OpenAppointment = loadable(() => import('../../../src/components/open-appointment'));

const AppointmentModule = () => {
    const [showOpenAppointment, setShowOpenAppointment] = useState<boolean>(false);
    const property = getPropertyData();
    const configLogo = getConfigFrontEnd({ name: 'LogoImage2' });

    useEffect(() => {
        setTimeout(() => {
            setShowOpenAppointment(true);
        }, 5000);
        return () => { };
    }, [])

    return <>
        {showOpenAppointment && <OpenAppointment
            config={{
                scriptUrl: 'https://appointment-online-v2.omnicasaweb.com/contact-appointment-embed.js',
                email: '',
                fonts: '"Work Sans",Arial,Verdana,sans-serif',
                fontSize: '14px',
                btnBackground: 'a5935f',
                logo: configLogo.url,
                key: CustomerInfo.apiV2Token,
                confirmReadDescriptionForSale: '',
                confirmReadDescription: '',
                confirmRequestProof: '',
                confirmEnoughMoney: '',
                confirmMaxPerson: '',
                showConfirmReadDescriptionForSale: 'yes',
                showConfirmReadDescription: 'yes',
                showConfirmRequestProof: 'yes',
                showConfirmEnoughMoney: 'yes',
                showConfirmMaxPerson: 'yes',
                showConfirmNeedPay: 'yes',
                showAllAppointment: '',
                conditionCustom1: '',
                conditionCustom2: '',
                loadingImage: ''
            }}
            propId={property.ID || -1}
        />}
    </>;
};

export default AppointmentModule;