import React from 'react';
import Image from '../../../components/gatsby-image';
import { getPropertyData } from '../../../components/use-property';
import { useTranslation } from 'react-i18next';

import './styles.scss';

export default function InformationBar() {
    const { t } = useTranslation();
    const property = getPropertyData();

    return (
        <div className="information__content row">
            <div className="col-sm-12 col-md-6 col-lg-3 col-information">
                <div className="information--item">
                    <Image className="information-icon" name="house" />
                    <p className="font-weight-bold">{property.SurfaceTotal} m²</p>
                    <p className="font-weight-bold">{t('SURFACE_TOTAL')}</p>
                </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-2 col-information">
                <div className="information--item">
                    <Image className="information-icon" name="sofa" />
                    <p className="font-weight-bold">{property.SurfaceLiving} m²</p>
                    <p className="font-weight-bold">{t('SURFACE_LIVING')}</p>
                </div>
            </div>
            <div className="col-sm-12 col-md-4 col-lg-2 col-information">
                <div className="information--item">
                    <Image className="information-icon" name="bed" />
                    <p className="font-weight-bold">{property.NumberOfBedRooms}</p>
                    <p className="font-weight-bold">{t('NUMBER_OF_BED_ROOMS')}</p>
                </div>
            </div>
            <div className="col-sm-12 col-md-4 col-lg-2 col-information">
                <div className="information--item">
                    <Image className="information-icon" name="bath" />
                    <p className="font-weight-bold">{property.NumberOfBathRooms}</p>
                    <p className="font-weight-bold">{t('NUMBER_OF_BATH_ROOMS')}</p>
                </div>
            </div>
            <div className="col-sm-12 col-md-4 col-lg-3 col-information">
                <div className="information--item">
                    <Image className="information-icon" name="toilet" />
                    <p className="font-weight-bold">{property.NumberOfToilets}</p>
                    <p className="font-weight-bold">{t('NUMBER_OF_TOILETS')}</p>
                </div>
            </div>
        </div>
    );
}
