import React from 'react';
import { getPropertyData } from './use-property';
import { getPropertyFieldData, NodePropertyField } from './use-property-field';
import { useTranslation } from 'react-i18next';
import EPClabel from './epc';

export interface IRenderPropertyFieldProps {
    name: string; // name of PropertyInfo
    id: string; // airtableId of PropertyField
    className?: string;
}

const RenderPropertyField = (props: IRenderPropertyFieldProps) => {
    const { t } = useTranslation();
    const property = getPropertyData();
    const propertyFields = getPropertyFieldData({}) as NodePropertyField[];

    const field = propertyFields.find((field) => field.airtableId === props.id);

    if (!field) {
        return null;
    }

    if (!property[field.Name] || property[field.Name] === '' || property[field.Name] === null || property[field.Name] === 0 || property[field.Name] === '0') return null;

    return (
        <>
            <div className="property-field">
                <span className="name">{t(props.name)}:</span>
                {/*@ts-ignore */}
                <span className="value">
                    {field.Prepend ? field.Prepend : ''} {property[field.Name]} {field.Append ? field.Append : ''}
                </span>
                {field.Name === 'EnergyPerformance' && <span className="EPC"><EPClabel /></span>}
            </div>
        </>
    );
};

export default RenderPropertyField;
