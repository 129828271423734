import { useStaticQuery, graphql } from 'gatsby';
import { IPropertyInfo } from 'src/model/airtable.model';

export interface NodePropertyInfo extends IPropertyInfo, Node {
    airtableId: string;
}

export interface IPropertyInfoData {
    infos: {
        nodes: NodePropertyInfo[];
    };
}

export function getPropertyInfoData(props: { name?: string; group?: string }) {
    const data = useStaticQuery<IPropertyInfoData>(graphql`
        query {
            infos: allAirtablePropertyInfo {
                nodes {
                    airtableId
                    Name
                    Field
                    Group
                    Active
                }
            }
        }
    `);

    if (!!props.name) {
        return data.infos.nodes.find((info) => info.Name === props.name);
    }

    if (!!props.group) {
        return data.infos.nodes.filter((info) => info.Group === props.group);
    }

    return data.infos.nodes;
}
