import React from 'react';
import { useTranslation } from 'react-i18next';
import { getPropertyInfoData, NodePropertyInfo } from '../../../components/use-property-info';
import RenderPropertyField from '../../../components/render-property-field';

export interface IPropertyInfoEPCProps { }

const PropertyInfoEPC = (props: IPropertyInfoEPCProps) => {
    const { t } = useTranslation();
    const epcInfo = getPropertyInfoData({ group: 'EPC' }) as NodePropertyInfo[];

    return (
        <>
            <h5>{t('EPC')}</h5>
            {epcInfo &&
                epcInfo.map((info, index) => {
                    if (!info.Field) return null;
                    return <RenderPropertyField key={index} id={info.Field[0]} name={info.Name} />;
                })}
        </>
    );
};

export default PropertyInfoEPC;
