import React, { useRef, useState } from 'react';
import Image from '../../../components/gatsby-image';
import { getPropertyData } from '../../../components/use-property';
import { IAirtableAttachment } from '../../..//model/airtable.model';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './styles.scss';
import { IPropertyPicture } from 'src/model/property.model';
import ReactSlick from 'react-slick';

const Slider = React.forwardRef((props: any, ref) => <ReactSlick ref={ref} {...props} />);

interface IBannerProps {
    images?: IAirtableAttachment[];
}

export default function Banner(props: IBannerProps) {
    const property = getPropertyData();
    const sliderRef = useRef(null);
    const [slides, setSlides] = useState<IPropertyPicture[]>(property.XLargePictureItems || []);
    const [slidesAttactment, setSlidesAttactment] = useState<IAirtableAttachment[]>(props.images || []);

    const settingSlider = {
        arrows: false,
        dots: false,
        fade: true,
        autoplay: true,
        autoplaySpeed: 500,
        centerMode: false,
        initialSlide: 0,
        slidesToScroll: 1,
        slidesToShow: 1,
        speed: 3000,
        infinite: true,
        lazyload: true
    };

    return (
        <>
            <div style={{ position: 'relative', width: '100%' }}>
                {slides.length > 0 && <Slider ref={sliderRef} {...settingSlider}>
                    {slides.map((image: IPropertyPicture, index: number) => (
                        <div key={index} className="item">
                            <Image className="parallax" name={image.Name} />
                        </div>
                    ))}
                </Slider>}
                {slides.length === 0 && slidesAttactment.length > 0 && <Slider ref={sliderRef} {...settingSlider}>
                    {slidesAttactment.map((image: IAirtableAttachment, index: number) => (
                        <div key={index} className="item">
                            <Image className="parallax" name={image.id} />
                        </div>
                    ))}
                </Slider>}
                <div className="banner__content">
                    <div className="info">
                        <h1 className="address">{property.Ident}</h1>
                        <h1 className="city">{property.City}</h1>
                        <h3 className="price">{property.Price}</h3>
                    </div>
                </div>
            </div>
        </>
    );
}
