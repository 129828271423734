import React from 'react';
import { useTranslation } from 'react-i18next';
import { getPropertyInfoData, NodePropertyInfo } from '../../../components/use-property-info';
import RenderPropertyField from '../../../components/render-property-field';

export interface IPropertyInfoPlanningProps { }

const PropertyInfoPlanning = (props: IPropertyInfoPlanningProps) => {
    const { t } = useTranslation();
    const planInfo = getPropertyInfoData({
        group: 'PLANNING_INFORMATION'
    }) as NodePropertyInfo[];

    return (
        <>
            <h5>{t('PLANNING_INFORMATION')}</h5>
            {planInfo &&
                planInfo.map((info, index) => {
                    if (!info.Field) return null;
                    return <RenderPropertyField key={index} id={info.Field[0]} name={info.Name} />;
                })}
        </>
    );
};

export default PropertyInfoPlanning;
