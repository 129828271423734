import { useStaticQuery, graphql, Node } from 'gatsby';
import { IPropertyField } from '../model/airtable.model';

export interface NodePropertyField extends IPropertyField, Node {
    airtableId: string;
}

export interface IPropertyFieldData {
    fields: {
        nodes: NodePropertyField[];
    };
}

export function getPropertyFieldData(props: { id?: string }) {
    const data = useStaticQuery<IPropertyFieldData>(graphql`
        query {
            fields: allAirtablePropertyField {
                nodes {
                    airtableId
                    Name
                    Prepend
                    Append
                }
            }
        }
    `);

    if (!!props.id) {
        return data.fields.nodes.find((field) => field.airtableId === props.id);
    }

    return data.fields.nodes;
}
