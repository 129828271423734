import React from 'react';
import HtmlBind from '../../../components/html-binding';
import './styles.scss';

export interface IDescriptionProps {
    value: string;
}

export default function Description(props: IDescriptionProps) {
    return (
        <div className="container-fluid" style={{ textAlign: 'justify' }}>
            {/*@ts-ignore */}
            <HtmlBind className="description__content" value={props.value} />
        </div>
    );
}
