import React from 'react';
import Image from '../../../components/gatsby-image';
import { getPropertyData } from '../../../components/use-property';
import './styles.scss';

function goToLink(url: string) {
    if (typeof window !== 'undefined') {
        window.open(url, '_blank');
    }
}

export default function Documents() {
    const property = getPropertyData();

    return (
        <div className="container-fluid">
            <div className="documents__content row">
                {property.DocumentItems &&
                    property.DocumentItems.map((document, index) => (
                        <div key={index} className="col-sm-12 col-md-6 col-lg-6">
                            <div className="file-item" onClick={() => goToLink(document.FileName)}>
                                <Image className="file-item__pdf" name="pdf" />
                                <span className="file-item__text">{document.Comment}</span>
                                <Image className="file-item__arrow" name="next" />
                            </div>
                        </div>
                    ))}
            </div>
        </div>
    );
}
