import React from 'react';
import Layout from '../../../src/views/template-01/layout';
import { graphql, Node, PageProps } from 'gatsby';
import { IPage, SectionTypeEnum } from '../../../src/model/airtable.model';
import Section from '../../../src/views/template-01/section';

import VirtualTour from '../../../src/views/template-01/virtual-tour';
import Documents from '../../../src/views/template-01/documents';
import Utilities from '../../../src/views/template-01/information-bar';
import Banner from '../../../src/views/template-01/banner';
import Description from '../../../src/views/template-01/description';
import Info from '../../../src/views/template-01/info';
import OpenAppointment from '../components/open-appointment';
import { getPropertyData } from 'src/components/use-property';
import { getPropertyInfoData, NodePropertyInfo } from 'src/components/use-property-info';
import { NodePropertyField, getPropertyFieldData } from 'src/components/use-property-field';
import loadable from '@loadable/component';

const Maps = loadable(() => import('../../../src/views/template-01/maps'));
const Video = loadable(() => import('../../../src/views/template-01/video'));
const Contact = loadable(() => import('../../../src/views/template-01/contact'));
const Gallery = loadable(() => import('../../../src/views/template-01/gallery'));

import '../css/custom.scss';

export type NodePageData = IPage & Node;

interface IPageProps {
    allAirtablePage: {
        nodes: NodePageData[];
    };
}

const PageTemplate: React.FC<PageProps<IPageProps>> = ({ data }) => {
    console.log(data.allAirtablePage.nodes);
    const property = getPropertyData();

    return (
        <>
            <Layout>
                <OpenAppointment />
                {data.allAirtablePage.nodes.map((component, index) => {

                    console.log(component);

                    const attachments = component.Attachments;

                    // render category
                    if (component.Type === SectionTypeEnum.BANNER) {
                        return (
                            <Section
                                name={component.Name}
                                type={component.Type}
                                index={index}
                                key={index}
                                lazyload={false}
                                className={component.Class}>
                                <Banner images={!!component.Attachments ? component.Attachments : undefined} />
                            </Section>
                        );
                    }

                    if (component.Type === SectionTypeEnum.PROPERTY_UTILITIES) {
                        return (
                            <Section
                                name={component.Name}
                                type={component.Type}
                                index={index}
                                key={index}
                                lazyload={true}
                                title={component.Title}
                                description={component.Description}
                                className={component.Class}>
                                <Utilities />
                            </Section>
                        );
                    }

                    if (component.Type === SectionTypeEnum.PROPERTY_DESCRIPTION) {

                        const descriptionInfo = getPropertyInfoData({
                            group: 'DESCRIPTION'
                        }) as NodePropertyInfo[];

                        if (!descriptionInfo || !descriptionInfo[0].Field) {
                            return null;
                        }

                        const field = getPropertyFieldData({ id: descriptionInfo[0].Field[0] || '' }) as NodePropertyField;

                        if (!field || !property[field.Name]) {
                            return null;
                        }

                        return (
                            <Section
                                name={component.Name}
                                type={component.Type}
                                index={index}
                                key={index}
                                lazyload={true}
                                title={component.Title}
                                description={component.Description}
                                className={component.Class}>
                                <Description value={property[field.Name] as string} />
                            </Section>
                        );
                    }

                    if (component.Type === SectionTypeEnum.PROPERTY_INFO) {
                        return (
                            <Section
                                name={component.Name}
                                type={component.Type}
                                index={index}
                                key={index}
                                lazyload={true}
                                className={component.Class}
                                title={component.Title}
                                description={component.Description}>
                                <Info image={(!!attachments && Array.isArray(attachments) && attachments.length > 0) ? attachments[0] : undefined} />
                            </Section>
                        );
                    }

                    if (component.Type === SectionTypeEnum.PROPERTY_IMAGES) {
                        return (
                            <Section
                                name={component.Name}
                                type={component.Type}
                                index={index}
                                key={index}
                                lazyload={true}
                                className={component.Class}
                                title={component.Title}
                                description={component.Description}>
                                <Gallery images={attachments} />
                            </Section>
                        );
                    }

                    if (component.Type === SectionTypeEnum.VIDEO) {
                        return (
                            <Section
                                name={component.Name}
                                type={component.Type}
                                index={index}
                                key={index}
                                lazyload={true}
                                className={component.Class}
                                title={component.Title}
                                description={component.Description}>
                                <Video attachment={component.Attachments[0]} />
                            </Section>
                        );
                    }

                    if (component.Type === SectionTypeEnum.PROPERTY_VIRTUAL_TOUR) {
                        if (!property.VirtualTour && !property.VirtualTour2) {
                            return null;
                        }
                        return (
                            <Section
                                name={component.Name}
                                type={component.Type}
                                index={index}
                                key={index}
                                lazyload={true}
                                className={component.Class}>
                                <VirtualTour title={component.Title} />
                            </Section>
                        );
                    }

                    if (component.Type === SectionTypeEnum.PROPERTY_DOCUMENTS) {
                        if (!property.DocumentItems || Array.isArray(property.DocumentItems) && property.DocumentItems.length === 0) {
                            return null;
                        }
                        return (
                            <Section
                                name={component.Name}
                                type={component.Type}
                                index={index}
                                key={index}
                                lazyload={true}
                                className={component.Class}
                                title={component.Title}
                                description={component.Description}>
                                <Documents />
                            </Section>
                        );
                    }

                    if (component.Type === SectionTypeEnum.CONTACT) {
                        return (
                            <Section
                                name={component.Name}
                                type={component.Type}
                                index={index}
                                key={index}
                                lazyload={true}
                                className={component.Class}
                                title={component.Title}
                                description={component.Description}>
                                <Contact />
                            </Section>
                        );
                    }

                    if (component.Type === SectionTypeEnum.MAPS) {
                        return (
                            <Section
                                name={component.Name}
                                type={component.Type}
                                index={index}
                                key={index}
                                lazyload={true}
                                className={component.Class}
                                title={component.Title}
                                description={component.Description}>
                                <Maps />
                            </Section>
                        );
                    }

                    return null;
                })}
            </Layout>
        </>
    );
};

export default PageTemplate;

export const query = graphql`
    query Pages {
        allAirtablePage {
            nodes {
                Name
                Title
                Description
                Type
                Class
                Attachments {
                    id
                    url
                }
            }
        }
    }
`;
