export enum SectionTypeEnum {
    PROPERTY_DESCRIPTION = 'property-description',
    PROPERTY_DOCUMENTS = 'property-documents',
    PROPERTY_IMAGES = 'property-images',
    PROPERTY_INFO = 'property-info',
    PROPERTY_UTILITIES = 'property-utilities',
    PROPERTY_VIRTUAL_TOUR = 'property-virtual-tour',
    VIDEO = 'video',
    BANNER = 'banner',
    CONTACT = 'contact',
    MAPS = 'maps'
}

export interface IAirtableData<T> {
    id: string;
    fields: T;
    createdTime: string;
}

export interface IAirtableAttachment {
    id: string;
    url: string;
    filename: string;
    size: number;
    type: string;
    thumbnails: {
        small: {
            url: string;
            width: string;
            height: string;
        };
        large: {
            url: string;
            width: string;
            height: string;
        };
        full: {
            url: string;
            width: string;
            height: string;
        };
    };
}

export interface IFrontEndConfig {
    Name: string;
    Value: string;
    Type: string;
    Attachments: IAirtableAttachment[];
    Active: boolean;
}

export interface IPage {
    Name: string;
    Title?: string;
    Description?: string;
    Attachments: IAirtableAttachment[];
    Type?: SectionTypeEnum;
    Class?: string;
    Active: boolean;
}

export interface IMenu {
    Name: string;
    Page?: string[];
    Active: boolean;
}

export interface IPropertyInfo {
    Name: string;
    Field?: string[];
    Group: string;
    Active: boolean;
}

export interface IPropertyField {
    Name: string;
    Prepend: string;
    Append: string;
}
